<template>
  <b-modal ref="my-modal" centered class="rounded-0" hide-footer :title="updateData.ad_soyad">
    <template #default>
      <div>
        <p>{{ updateData.mesaj }}</p>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  components: {},
  props: {
    openModal: {
      type: Boolean,
    },
    updateData: {
      type: Object,
    },
  },
  methods: {
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn');
      this.$emit('closeModal', true);
    },
  },
  watch: {
    openModal: {
      handler(val) {
        if (val == true) {
          this.toggleModal();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
