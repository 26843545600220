<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="openModal"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      width="500px"
      @change="(val) => $emit('closeModal', val)"
      @hidden="resetForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2">
          <h5 class="mb-0">Üye İşlemleri</h5>
          <div>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form
            @submit.stop.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
            autocomplete="off"
            enctype="multipart/form-data"
            class="p-2"
          >
            <b-row>
              <b-col cols="12">
                <validation-provider name="Kullanıcı Adı" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Kullanıcı Adı">
                    <b-form-input
                      class="rounded-0"
                      ref="kullanici_adi"
                      v-model="form.kullanici_adi"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <validation-provider name="Şifre" :rules="{ required: !form._id && true, min: 4 }" v-slot="validationContext">
                  <b-form-group label="Şifre">
                    <b-form-input
                      class="rounded-0"
                      ref="sifre"
                      type="password"
                      v-model="form.sifre"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider
                  name="Şifre Tekrarı"
                  :rules="{ required: !form._id && true, confirmed: 'Şifre' }"
                  v-slot="validationContext"
                >
                  <b-form-group label="Şifre Tekrarı">
                    <b-form-input
                      class="rounded-0"
                      ref="sifre_tekrari"
                      type="password"
                      v-model="form.sifre_tekrari"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <validation-provider name="Ad" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Ad">
                    <b-form-input
                      class="rounded-0"
                      ref="ad"
                      v-model="form.ad"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider name="Soyad" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Soyad">
                    <b-form-input
                      class="rounded-0"
                      ref="soyad"
                      v-model="form.soyad"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <validation-provider name="E Mail" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="E Mail">
                    <b-form-input
                      class="rounded-0"
                      ref="e_mail"
                      v-model="form.e_mail"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider name="Telefon" :rules="{ required: true }" v-slot="validationContext">
                  <b-form-group label="Telefon">
                    <b-form-input
                      class="rounded-0"
                      ref="telefon"
                      v-model="form.telefon"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <validation-provider name="Adres" :rules="{ required: false }" v-slot="validationContext">
                  <b-form-group label="Adres">
                    <b-form-input
                      class="rounded-0"
                      ref="adres"
                      v-model="form.adres"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <validation-provider name="Hakkında" :rules="{ required: false }" v-slot="validationContext">
                  <b-form-group label="Hakkında">
                    <b-form-textarea
                      class="rounded-0"
                      ref="hakkimda"
                      v-model="form.hakkimda"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="4">
                <validation-provider name="Şehir" :rules="{ required: false }" v-slot="validationContext">
                  <b-form-group label="Şehir">
                    <b-form-input
                      class="rounded-0"
                      ref="sehir"
                      v-model="form.sehir"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="5">
                <validation-provider name="Ülke" :rules="{ required: false }" v-slot="validationContext">
                  <b-form-group label="Ülke">
                    <b-form-input
                      class="rounded-0"
                      ref="ulke"
                      v-model="form.ulke"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="3">
                <validation-provider name="Posta Kodu" :rules="{ required: false }" v-slot="validationContext">
                  <b-form-group label="Posta Kodu">
                    <b-form-input
                      class="rounded-0"
                      ref="posta_kodu"
                      v-model="form.posta_kodu"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-1-live-feedback"
                    />

                    <b-form-invalid-feedback id="input-1-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="$t('profilResmi')">
                  <b-form-file
                    class="rounded-0"
                    placeholder="Bir resim seçiniz"
                    drop-placeholder="Drop file here..."
                    no-drop
                    v-model="profil"
                    accept="image/jpeg, image/png, image/jpg"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end">
              <b-button squared class="mr-2" @click="hide">
                <feather-icon icon="XSquareIcon" class="mr-50" />
                <span class="align-middle">İptal</span>
              </b-button>
              <b-button squared type="submit" variant="primary" :disabled="isLoading">
                <b-spinner small v-show="isLoading" />
                <feather-icon icon="CheckSquareIcon" class="mr-50" />
                <span class="align-middle">
                  {{ form._id == null ? 'Ekle' : 'Güncelle' }}
                </span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      profil: null,
      form: {
        _id: null,
        k_no: null,
        kullanici_adi: null,
        sifre: '',
        sifre_tekrari: '',
        ad: null,
        soyad: null,
        e_mail: null,
        telefon: null,
        adres: '',
        sehir: '',
        ulke: '',
        posta_kodu: '',
        hakkimda: '',
      },
      savedForm: false,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.form = {
        _id: null,
        k_no: null,
        kullanici_adi: null,
        sifre: '',
        sifre_tekrari: '',
        ad: null,
        soyad: null,
        e_mail: null,
        telefon: null,
        adres: '',
        sehir: '',
        ulke: '',
        posta_kodu: '',
        hakkimda: '',
      };

      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
      this.$refs.kullanici_adi.focus();
    },
    onSubmit() {
      this.savedForm = true;
      let formData = new FormData();
      let forms = this.form;
      Object.keys(forms).map(function (key) {
        formData.append(key, forms[key]);
      });
      if (this.profil !== null) {
        formData.append('uyeprofil', this.profil, this.profil.name);
      }
      const baseUrl = this.form._id == null ? 'uyeEkle' : 'uyeGuncelle';

      this.$store
        .dispatch(baseUrl, formData)
        .then((res) => {
          if (res.data.success == true) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ekleme`,
                icon: 'CheckSquareIcon',
                variant: 'success',
                text: this.form._id == null ? `Ekleme başarılı.` : `Güncelleme başarılı.`,
              },
            });
            this.savedForm = false;
            this.form._id == null && this.resetForm();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Uyari`,
                icon: 'AlertTriangleIcon',
                variant: 'warning',
                text: res.data.message,
              },
            });
            this.savedForm = false;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Uyari`,
              icon: 'AlertTriangleIcon',
              variant: 'warning',
              text:
                Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
            },
          });
          this.savedForm = false;
        });
    },
  },
  computed: {
    isLoading() {
      return this.savedForm == true ? true : false;
    },
  },
  watch: {
    updateData(val) {
      if (val != null) {
        this.form._id = val._id;
        this.form.k_no = val.k_no;
        this.form.kullanici_adi = val.kullanici_adi;
        this.form.ad = val.ad;
        this.form.soyad = val.soyad;
        this.form.e_mail = val.e_mail;
        this.form.telefon = val.telefon;
        this.form.adres = val.adres;
        this.form.sehir = val.sehir;
        this.form.ulke = val.ulke;
        this.form.posta_kodu = val.posta_kodu;
        this.form.hakkimda = val.hakkimda;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
